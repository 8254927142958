import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  TravelPlanDisplayCard,
  TravellersDisplayCard,
  PrimaryButton,
  SecondaryButton,
  GoBack,
} from "../../components"
import { getCurrentPath } from "../../redux/actions"

const Review = ({ location, getCurrentPath, selectedPlan }) => {
  const [isValidPlan, setIsValidPlan] = useState(true)

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  }, [selectedPlan, location.pathname])

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title="now let's review your policy"
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                `}
              >
                {selectedPlan && <PlanDisplayCard />}
                <TravelPlanDisplayCard />
                <TravellersDisplayCard />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 4rem;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  onClick={() => navigate("/get-quote-purchase/payment-info")}
                >
                  continue
                </PrimaryButton>
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={() => navigate("/get-quote-purchase")}
                >
                  Go back
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut="plan"
            text="Looks like you have not selected a "
            actionText="go back to select one."
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan }) => ({
  selectedPlan,
})

export default connect(mapStateToProps, { getCurrentPath })(Review)
